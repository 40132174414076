<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.sign_box {
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  width: 196px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  background-color: #fff;
}
.signActive {
  background-color: #fff;
  color: #333333 !important;
}
.copyActive {
  color: #0090f0;
}
.per_box {
  padding-bottom: 50px;
  box-sizing: border-box;
}
.bj {
  position: relative;
  height: 200px;
  .bj_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.per_top {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  color: #fff;
  .per_left {
    display: flex;
    position: relative;

    .per_tx {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-top: 68px;
    }
    .info_box {
      margin-left: 27px;
      .name {
        font-size: 20px;
        font-weight: 700;
        margin: 80px 0 10px 0;
      }
      .tagName {
        position: relative;
        display: flex;
        font-size: 14px;
        // cursor: pointer;
        .img2 {
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
        .back_bj {
          width: 100%;
          height: 25px;
          background: transparent;
        }
        .yaoqing_box {
          position: absolute;
          top: 25px;
          left: 0;
          z-index: 222;
          width: 270px;
          height: 305px;
          background: url("../../assets/img/personal/bg_5_2.png");
          background-size: 100%;
          padding: 40px 30px;
          box-sizing: border-box;
          color: #333;
          text-align: center;
          margin-left: -40px;
          .tit {
            font-size: 16px;
            font-weight: 700;
          }
          .ewm_box {
            font-size: 12px;
            margin-top: 20px;
            font-weight: 700;
            .ewm {
              // width: 120px;
              // height: 120px;
              // margin-bottom: 20px;
              margin: 0 auto;
            }
            div {
              margin: 10px 0;
            }
          }
          .lianjie_box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 24px;
            line-height: 24px;
            border: 1px solid #f2f2f2;
            font-size: 12px;
            border-radius: 12px;
            // margin-top: 20px;
            .dizhi {
              width: 127px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 8px;
            }
            .dizhi_btn {
              color: #666;
              width: 83px;
              height: 24px;
              background: #f2f2f2;
              border-radius: 12px;
              cursor: pointer;

              img {
                width: 15px;
                height: 15px;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .address_tags {
        display: flex;
        font-size: 12px;
        color: #fff;
        text-align: center;
        margin: 15px 0;
        div {
          padding: 0 10px;
          height: 20px;
          line-height: 20px;
          box-sizing: border-box;
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.1);
          margin-right: 10px;
        }
      }
    }
  }
  .per_right {
    display: flex;
    align-items: center;
    margin-top: 20px;
    z-index: 1;
    .time {
      .num {
        font-size: 24px;
        span {
          font-size: 12px;
        }
      }
      .listName {
        font-size: 16px;
        text-align: center;
      }
    }
    .border {
      width: 1px;
      height: 33px;
      background-color: #fff;
      margin: 0 50px;
      margin-top: 15px;
    }
    .qiandao {
      width: 100px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 16px;
      margin-left: 100px;
      cursor: pointer;
    }
    .qiandao2 {
      cursor: text;
    }
  }
}
.per_body {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-top: 80px;
  .body_left {
    padding: 25px 0;
    box-sizing: border-box;
    width: 220px;
    height: 400px;
    margin-right: 50px;
    background-color: #fff;
    border-radius: 6px;

    .per_tabs {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      color: #656565;
      padding-left: 50px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 8px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .active {
      color: #0090f0;
      background-color: #f2f2f2;
      border-left: 4px solid #0090f0;
      // background: url(../../assets/img/home/i1.jpg) no-repeat;
    }
  }
  .body_right {
    width: 930px;
    border-radius: 6px;
    background-color: #fff;
  }
}
</style>
<template>
  <div class="per_box">
    <!-- 签到弹窗 -->
    <div v-if="signYes" class="sign_box">今日签到获得2积分</div>
    <!-- 个人信息 -->
    <div class="bj">
      <img class="bj_img" src="../../assets/img/personal/per_bj.png" alt />
      <div class="per_top">
        <div class="per_left">
          <img class="per_tx" :src="info.face" alt />
          <div class="info_box">
            <div class="name">{{ info.nickname }}</div>
            <div class="address_tags">
              <!-- <div>{{ info.sex }}</div> -->
              <div v-if="info.sex == 0">女</div>
              <div v-if="info.sex == 1">男</div>
              <div v-if="info.sex == 2">保密</div>
              <div>{{ info.address }}</div>
              <!-- <div>郑州市</div> -->
            </div>
            <div class="tagName">
              <div
                :class="{ copyActive: copyActive }"
                @mouseenter="copyActive = true"
                @mouseleave="copyActive = false"
              >
                <div style="cursor: pointer">
                  <img
                    v-if="copyActive"
                    class="img2"
                    src="../../assets/img/personal/icon_5_14.png"
                    alt
                  />
                  <img
                    v-else
                    class="img2"
                    src="../../assets/img/personal/icon_5_13.png"
                    alt
                  />
                  <ins>邀请好友一起学习</ins>
                </div>
                <div class="back_bj" v-if="copyActive"></div>
                <div class="yaoqing_box" v-if="copyActive">
                  <div class="tit">邀请好友</div>
                  <div class="ewm_box">
                    <!-- <img
                      class="ewm"
                      src="../../assets/img/home/code.png"
                      alt=""
                    /> -->
                    <qriously class="ewm" :value="url2" :size="120" />
                    <div>扫码分享</div>
                  </div>
                  <div class="lianjie_box">
                    <div class="dizhi">{{ url }}</div>
                    <div
                      class="dizhi_btn"
                      @click="copyUrl"
                      :data-clipboard-text="url"
                    >
                      <img
                        src="../../assets/img/personal/icon_lianjie.png"
                        alt=""
                      />
                      复制链接
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="per_right">
          <div class="time">
            <div class="num">
              {{ info.study_h }}
              <span>H</span>
            </div>
            <div class="listName">学习时长</div>
          </div>
          <div class="border"></div>
          <div class="time">
            <div class="num">{{ info.score }}</div>
            <div class="listName">积分</div>
          </div>
          <!-- <div class="border"></div>
          <div class="time">
            <div class="num">
              12
              <span>张</span>
            </div>
            <div class="listName">优惠券</div>
          </div> -->
          <div v-if="info.points == 1" class="qiandao qiandao2">已签到</div>
          <div
            v-else
            class="qiandao"
            :class="{ signActive: signActive }"
            @mouseenter="signActive = true"
            @mouseleave="signActive = false"
            @click="goSign"
          >
            签到领积分
          </div>
        </div>
      </div>
    </div>
    <div class="per_body">
      <!-- 左侧边栏 -->
      <div class="body_left">
        <div
          class="per_tabs"
          :class="{ active: active == index }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="changeTags(index)"
        >
          <img v-if="active == index" :src="item.img2" alt />
          <img v-else :src="item.img1" alt />
          <div>{{ item.name }}</div>
        </div>
      </div>
      <!-- 右侧内容区域 -->
      <div class="body_right">
        <!-- 学习记录 -->
        <Study v-show="active == -1"></Study>
        <MyClass v-if="active == 0"></MyClass>
        <MyOrder v-if="active == 1"></MyOrder>
        <Zichan v-if="active == 2"></Zichan>
        <StudyCard v-if="active == 3"></StudyCard>
        <MsgCenter v-if="active == 4"></MsgCenter>
        <Zhanghao @func="getMsgFormSon" v-if="active == 5"></Zhanghao>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import Study from "@/components/personal/study.vue";
import MyClass from "@/components/personal/myClass.vue";
import MyOrder from "@/components/personal/myOrder.vue";
import Zichan from "@/components/personal/zichan.vue";
import StudyCard from "@/components/personal/studyCard.vue";
import MsgCenter from "@/components/personal/msgCenter.vue";
import Zhanghao from "@/components/personal/zhanghao.vue";
import { getInfo, Sign, qrcode } from "../../assets/js/personal";
export default {
  components: {
    Study,
    MyClass,
    MyOrder,
    Zichan,
    StudyCard,
    MsgCenter,
    Zhanghao,
  },
  data() {
    return {
      user_id: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "",
      info: {},
      id: "",
      url: "",
      url2: "",
      active: -1,
      jifen: 200,
      // 签到
      sign: false,
      signActive: false,
      signYes: false,
      copyActive: false,
      tabList: [
        {
          name: "我的课程",
          img1: require("../../assets/img/personal/icon_kc1.png"),
          img2: require("../../assets/img/personal/icon_kc2.png"),
        },
        {
          name: "我的订单",
          img1: require("../../assets/img/personal/icon_dd1.png"),
          img2: require("../../assets/img/personal/icon_dd2.png"),
        },
        {
          name: "推广收益",
          img1: require("../../assets/img/personal/icon_zc1.png"),
          img2: require("../../assets/img/personal/icon_zc2.png"),
        },
        {
          name: "学习卡",
          img1: require("../../assets/img/personal/icon_xxk1.png"),
          img2: require("../../assets/img/personal/icon_xxk2.png"),
        },
        {
          name: "消息中心",
          img1: require("../../assets/img/personal/icon_msg1.png"),
          img2: require("../../assets/img/personal/icon_msg2.png"),
        },
        {
          name: "账号管理",
          img1: require("../../assets/img/personal/icon_zh1.png"),
          img2: require("../../assets/img/personal/icon_zh2.png"),
        },
      ],
    };
  },
  mounted() {
    // console.log(this.$route.query);
    // this.active = this.$route.query.active;
    // this.user_id = JSON.parse(window.localStorage.getItem("userInfo"))
    //       ? JSON.parse(window.localStorage.getItem("userInfo")).id
    //       : "",
    // console.log(window.location.host);
    // console.log(window.location.href);
    this.url =
      window.location.host + "/#" + `/login?active=1&user_id=${this.user_id}`;
    this.url2 =
      "http://" +
      window.location.host +
      `/index.html#/pages/login/register?user_id=${this.user_id}`;
      // console.log( this.url2);
    if (!this.user_id) {
      this.$router.push({
        path: "/login",
        query: {
          active: 0,
        },
      });
    }
    if (window.localStorage.getItem("perHeader")) {
      this.active = window.localStorage.getItem("perHeader");
    } else {
      this.active = -1;
    }
    this.id = JSON.parse(window.localStorage.getItem("userInfo")).id;
    // console.log(this.id);
    this.getInfo();
  },

  destroyed() {
    window.localStorage.setItem("perHeader", -1);
  },
  methods: {
    // 获取子组件
    getMsgFormSon(data) {
      // console.log(data);
      this.info = data;
    },
    // 获取个人信息
    getInfo() {
      getInfo({
        id: this.id,
      }).then((res) => {
        // console.log(res);
        this.info = res.data.info;
        window.sessionStorage.setItem("info", JSON.stringify(res.data.info));
      });
    },
    changeTags(i) {
      this.active = i;
      window.localStorage.setItem("perHeader", i);
    },
    // 签到
    goSign() {
      Sign({
        user_id: this.user_id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.signYes = true;
          this.timer = setInterval(() => {
            clearInterval(this.timer);
            this.signYes = false;
            this.jifen = this.jifen + 2;
            this.sign = true;
            this.getInfo();
          }, 2000);
        }
      });
    },
    // 点击复制
    copyUrl() {
      // let _this = this;
      let clipboard = new Clipboard(".dizhi_btn"); // 这里括号里填写上面点击事件绑定的class名
      clipboard.on("success", (e) => {
        // 复制成功，提示根据自己项目实际使用的UI来写
        this.$message.success("复制成功，快去分享吧~");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制，提示根据自己项目实际使用的UI来写
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
